import { KEY_COOKIE, objectType } from '@type/customType';
import { encryptText, isServer } from '@utils/helper';
import { parse } from 'cookie';
import { useCallback, useMemo, useState } from 'react';
import { I_Project, I_ProtectPasswordConfig } from '../types';

interface Props {
  detailProject?: I_Project;
  configProtectPassword?: I_ProtectPasswordConfig;
  listProjectGuardOpenedCookie?: string;
  passwordTourCookie?: string;
}

export const useCheckPasswordTour = ({
  detailProject,
  configProtectPassword,
  listProjectGuardOpenedCookie = '[]',
  passwordTourCookie,
}: Props) => {
  const [isPasswordCorrectly, setIsPasswordCorrectly] = useState(false);

  const cookies: () => objectType = useCallback(
    () => (!isServer ? parse(document.cookie) : {} || {}),
    [],
  );

  const listProjectGuardOpened = useMemo(
    () =>
      JSON.parse(
        (isServer
          ? listProjectGuardOpenedCookie
          : cookies()[KEY_COOKIE.LIST_PROJECT_PASS_GUARD] || '[]') || '[]',
      ),
    [cookies, listProjectGuardOpenedCookie],
  );

  const isOpenGuardPassword = useMemo(() => {
    if (configProtectPassword?.isEnabled && configProtectPassword?.password) {
      const previousPassword = isServer
        ? passwordTourCookie
        : cookies()[KEY_COOKIE.PASSWORD_TOUR] || '';
      const currentPassword = encryptText(configProtectPassword?.password);

      if (!configProtectPassword?.isAlwaysAsk) {
        if (
          isPasswordCorrectly ||
          (listProjectGuardOpened.indexOf(encryptText(detailProject?.id)) >
            -1 &&
            previousPassword === currentPassword)
        )
          return false;
        return true;
      }
      return !isPasswordCorrectly;
    }

    return false;
  }, [
    cookies,
    detailProject,
    passwordTourCookie,
    isPasswordCorrectly,
    configProtectPassword,
    listProjectGuardOpened,
  ]);

  const onCheckGuardPassword = useCallback(() => {
    setIsPasswordCorrectly(true);
    if (!configProtectPassword?.isAlwaysAsk) {
      document.cookie = `${KEY_COOKIE.PASSWORD_TOUR}=${encryptText(
        configProtectPassword?.password,
      )}; path=/`;
      const projectId = encryptText(detailProject?.id);
      if (!listProjectGuardOpened.length)
        document.cookie = `${
          KEY_COOKIE.LIST_PROJECT_PASS_GUARD
        }=${JSON.stringify([projectId])}; path=/`;
      else
        document.cookie = `${
          KEY_COOKIE.LIST_PROJECT_PASS_GUARD
        }=${JSON.stringify(
          Array.from(new Set([...listProjectGuardOpened, projectId])),
        )}; path=/`;
    }
  }, [configProtectPassword, detailProject, listProjectGuardOpened]);

  return useMemo(
    () => ({
      isPasswordCorrectly,
      setIsPasswordCorrectly,
      isOpenGuardPassword,
      onCheckGuardPassword,
    }),
    [isOpenGuardPassword, isPasswordCorrectly, onCheckGuardPassword],
  );
};
